* {
  box-sizing: border-box;
}
a {
  color: #000000;
}
.home {
  .main {
    position: relative;
  }
  ul {
    padding-left: 0;
    li {
      padding-left: 0;
      list-style-type: none;
    }
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .header {
    width: 100%;
    background-color: #fafafa;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    .header_inner {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 70px;
      height: 100%;
    }
  }

  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 1450px;
    padding: 0 70px;

    .main_profile {
      width: 100%;
      margin-bottom: 40px;
      img {
        width: 100%;
      }
    }

    .artist_instruction {
      width: 100%;
      margin-bottom: 50px;
      .title {
        font-weight: 700;
        font-size: 20px;
      }
      .description {
        font-weight: 400;
        font-size: 20px;
      }
      .sns {
        margin-top: 50px;
        ul {
          display: flex;
          flex-direction: row;
          li {
            margin-right: 20px;
            img {
              width: 40px;
            }
          }
        }
      }
    }

    .album_introduction_title {
      margin: 40px 0px;
    }

    .album_introduction {
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .album_cover {
        width: 350px;
        margin-right: 40px;
      }
      .title {
        width: 270px;
        margin-bottom: 12px;
      }
      .description {
        margin-top: 20px;
        margin-left: 0;
        margin-bottom: 0;
        padding-left: 0;
        line-height: 1.5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 15px;
      }
    }

    .track_list {
      margin-bottom: 50px;

      .title {
        width: 100%;
        font-weight: 700;
        font-size: 25px;
      }
      ul {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        li {
          margin-right: 15px;
          list-style-type: none;
          .song_title {
            cursor: pointer;
            font-size: 16px;
            pointer-events: cursor;
            transition: font-size 0.3s ease;
            &:hover {
              font-size: 18px;
            }
          }
          .song_description {
            line-height: 1.5;
            font-size: 15px;
            overflow: hidden;
            max-height: 0;
            transition: max-height 0.3s ease-in-out;
            &.is_opened {
              max-height: 500px;
            }
          }
        }
      }
      .small_title {
        font-weight: 700;
      }
      .credit_description {
        line-height: 1;
        font-size: 14px;
        column-count: 2;
        column-gap: 20px;
      }
      .streaming_description {
        font-size: 14px;
        column-count: 2;
        column-gap: 20px;
        ul {
          margin-top: 0;
          li {
            margin-bottom: 14px;
          }
        }
      }
    }
  }

  .videos {
    .title {
      font-weight: 700;
      font-size: 25px;
      margin-bottom: 50px;
    }
    .video_item {
      margin-bottom: 60px;

      iframe {
        max-width: '930';
        height: '615';
        margin-bottom: 20px;
      }

      .mv_title {
        font-size: 20px;
        font-weight: 500;
      }

      .mv_lyrics {
        font-size: 16px;
      }
    }
  }

  .merchandise {
    display: flex;
    align-items: center;
    column-count: 2;
    column-gap: 20px;
    margin-bottom: 30px;
    img {
      width: 400px;
      margin-right: 40px;
    }
    div {
      display: flex;
      flex-direction: column;
      .title {
        font-weight: 700;
        font-size: 20px;
      }
      ul {
        padding-left: 0;
        li {
          padding-left: 0;
          list-style-type: none;
          margin-bottom: 14px;
          a {
            color: #000000;
          }
        }
      }
    }
  }

  .photos {
    margin-bottom: 50px;
  }

  footer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(174, 174, 174);
    letter-spacing: 0.03em;
    padding: 50px;
  }
}
