// size
$mobile-min-height-size: 550px;
$mobile-min-size: 320px;
$mobile-size: 480px;
$pc-size: 1025px;

@mixin mobile-min {
  @media screen and (max-width: #{$mobile-min-size}) and (max-height: #{$mobile-min-height-size}) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: #{$mobile-size}) {
    @content;
  }
}

@mixin pc {
  @media screen and (min-width: #{$pc-size}) {
    @content;
  }
}
