@use './mixins.scss';

@include mixins.mobile {
  .home {
    .header {
      display: none;
    }
    .container {
      width: 100%;
      padding: 17px;

      .main_profile {
        width: 100%;
        margin-bottom: 22px;
        img {
          width: 100%;
        }
      }
      .artist_instruction {
        width: 100%;
        margin-bottom: 22px;
        .title {
          font-size: 16px;
        }
        .description {
          font-size: 14px;
        }
        .sns {
          margin-top: 31px;
          ul {
            li {
              margin-right: 13px;
              img {
                width: 43px;
              }
            }
          }
        }
      }

      .album_introduction_title {
        font-size: 22px;
        margin-bottom: 22px;
      }

      .album_introduction {
        flex-direction: column;
        height: 100%;
        .album_cover {
          width: 100%;
          margin-right: 0;
          margin-bottom: 31px;
        }
        .title {
          width: 100%;
          font-size: 17px;
          margin-bottom: 34px;
        }
        .description {
          margin-bottom: 12px;
          margin-top: 12px;
          height: 100%;
        }
      }

      .track_list {
        margin-bottom: 43px;

        .title {
          font-size: 22px;
        }
        .description {
          font-size: 14px;
          margin-bottom: 22px;
        }
        ul {
          li {
            margin-right: 0;
          }
        }
        .small_title {
          font-size: 16px;
        }
        .credit_description {
          column-gap: 15px;
          font-size: 13px;
        }
        .streaming_description {
          font-size: 14px;
          column-count: 2;
          column-gap: 15px;
          ul {
            li {
              margin-bottom: 11px;
            }
          }
        }
      }

      .videos {
        margin-bottom: 30px;
        .title {
          font-size: 22px;
          margin-bottom: 22px;
        }
        .video_item {
          margin-bottom: 33px;
          iframe {
            max-width: 410px;
            height: 200px;
            margin-bottom: 12px;
          }
          .mv_title {
            font-size: 16px;
          }
          .mv_lyrics {
            font-size: 14px;
          }
        }
      }

      .merchandise {
        flex-direction: column;
        align-items: flex-start;
        column-count: 0;
        img {
          width: 100%;
          margin-right: 0;
        }
        div {
          .title {
            margin-bottom: 13px;
          }
          ul {
            li {
              margin-bottom: 11px;
              font-size: 14px;
            }
          }
        }
      }

      .photos {
        margin-bottom: 31px;
      }

      footer {
        padding: 30px;
        font-size: 10px;
      }
    }
  }
}
